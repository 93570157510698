import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import ColourBlock from '@components/colour-block';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
import { Tick, Cancel } from '@jsluna/icons';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const ImageWithCaption = makeShortcode("ImageWithCaption");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#lunas-layout-principles"
            }}>{`Luna's layout principles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#grid"
            }}>{`Grid`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#breakpoints"
            }}>{`Breakpoints`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#spacing"
            }}>{`Spacing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#design-templates"
            }}>{`Design templates`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#landmarks"
            }}>{`Landmarks`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "lunas-layout-principles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lunas-layout-principles",
        "aria-label": "lunas layout principles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Luna's layout principles`}</h2>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="Responsive" titleElement="h3" border mdxType="GridListItem">
    Our layout principles adapt to different screen sizes, so that users get a
    great experience regardless of what device they’re using.
  </GridListItem>
  <GridListItem title="Flexible" titleElement="h3" border mdxType="GridListItem">
    All of our defaults can be modified to meet the needs of different users.
  </GridListItem>
  <GridListItem title="Mobile first" titleElement="h3" border mdxType="GridListItem">
    In both design and code, we cater for the smallest breakpoint first, then
    customise as the screen size increases.
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "grid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#grid",
        "aria-label": "grid permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Grid`}</h2>
    <p>{`The structure of a page is created through the grid. It controls the positioning of components, the spacing between them and how wide they can expand.`}</p>
    <p>{`The grid normally makes use of a 12-column layout to cater for either 2, 3 or 4 components being displayed side-by-side.`}</p>
    <p>{`However, there’s flexibility for other approaches if the design requires it.`}</p>
    <h3>{`Margins, columns and gutters`}</h3>
    <p>{`The Luna grid is made up of three different parts. These are margins, columns, and gutters.`}</p>
    <GridList columns="3" mdxType="GridList">
      <GridListItem removePadding="true" mdxType="GridListItem">
  <ImageWithCaption imageName="layout-grid-margins" alt="Example of grid margins highlighted on an interface" caption="Margins are the spaces on the left and right edges of the screen (shown above in yellow)." type="png" mdxType="ImageWithCaption" />
      </GridListItem>
      <GridListItem removePadding="true" mdxType="GridListItem">
  <ImageWithCaption imageName="layout-grid-columns" alt="Example of grid columns highlighted on an interface" caption="Columns show the space available for content to begin and end (shown above in red). Content can span multiple columns." type="png" mdxType="ImageWithCaption" />
      </GridListItem>
      <GridListItem removePadding="true" mdxType="GridListItem">
  <ImageWithCaption imageName="layout-grid-gutters" alt="Example of grid gutters highlighted on an interface" caption="Gutters are the spaces between columns (shown above in blue). They provide negative space to separate content." type="png" mdxType="ImageWithCaption" />
      </GridListItem>
    </GridList>
    <h3>{`Fluid and fixed grids`}</h3>
    <p>{`By default the grid will make use of fluid width columns. It will calculate margins and gutters first, then the columns will adjust to fill any remaining space.`}</p>
    <p>{`To prevent the grid from scaling, a maximum screen width can be set. At that point the grid will become fixed, with known values for column size.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="layout-fluid-grids" alt="Example of fluid grids on display sizes 320px, 768px and 1024px" caption="Fluid grids on display sizes 320px, 768px and 1024px." type="png" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="layout-fixed-grids" alt="Example of fixed grids at 1280px and 1440px on a 1440px display and 1800px on a 2560px display" caption="Fixed grids at 1280px and 1440px on a 1440px display and 1800px on a 2560px display." type="png" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <h3>{`Implementing the grid in projects`}</h3>
    <p>{`The easiest way to implement gutters and columns in your code is with the grid component.`}</p>
    <p>{`The container component can be also used to set a maximum width and outer margin for the content within.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "breakpoints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breakpoints",
        "aria-label": "breakpoints permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Breakpoints`}</h2>
    <p>{`Breakpoints allow for the layout to change on different screen sizes, so that the experience is always optimised for the device the user’s viewing it on.`}</p>
    <p>{`For example, a product listing page might show 4 columns on a mobile device and 8 columns on a tablet.`}</p>
    <p>{`Luna has a core set of breakpoints that cover common device groups. You can determine extra breakpoints if needed.`}</p>
    <TableContainer className="ln-u-margin-bottom" mdxType="TableContainer">
  <TableHeader mdxType="TableHeader">
    <TableHeaderRow mdxType="TableHeaderRow">
      <TableHeaderCell mdxType="TableHeaderCell">Breakpoint</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">SASS name</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Minimum width</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">default</TableCell>
      <TableCell mdxType="TableCell"></TableCell>
      <TableCell mdxType="TableCell">0px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">extra extra small</TableCell>
      <TableCell mdxType="TableCell">xs</TableCell>
      <TableCell mdxType="TableCell">480px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">extra small</TableCell>
      <TableCell mdxType="TableCell">ss</TableCell>
      <TableCell mdxType="TableCell">600px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">small</TableCell>
      <TableCell mdxType="TableCell">sm</TableCell>
      <TableCell mdxType="TableCell">720px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">medium</TableCell>
      <TableCell mdxType="TableCell">md</TableCell>
      <TableCell mdxType="TableCell">960px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">large</TableCell>
      <TableCell mdxType="TableCell">lg</TableCell>
      <TableCell mdxType="TableCell">1200px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">extra large</TableCell>
      <TableCell mdxType="TableCell">xl</TableCell>
      <TableCell mdxType="TableCell">1400px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">extra extra large</TableCell>
      <TableCell mdxType="TableCell">xxl</TableCell>
      <TableCell mdxType="TableCell">1800px</TableCell>
    </TableRow>
  </TableBody>
    </TableContainer>
    <hr></hr>
    <h2 {...{
      "id": "spacing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#spacing",
        "aria-label": "spacing permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Spacing`}</h2>
    <h3>{`Baseline grid`}</h3>
    <p>{`Many components within Luna are aligned to an 8px grid. But for some small components, like iconography and typography, a 4px grid can be used instead.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="layout-8px-baseline-grid" alt="Example of 8px baseline grid" caption="8px baseline grid: All components align to an 8px square baseline grid for mobile, tablet and desktop." type="png" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="layout-4px-baseline-grid" alt="Example of 4px baseline grid" caption="4px baseline grid: Typography, iconography, and some elements within components can align to a 4px grid." type="png" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <h3>{`Click and touch targets`}</h3>
    <p>{`Components within Luna use a standard height of 48px. Larger components go up to 80px.`}</p>
    <p>{`Clickable or touchable targets should be at least 48px by 48px.`}</p>
    <p>{`For smaller components like icons, you can use invisible padding to reach the target size.`}</p>
    <GridListItem removePadding="true" mdxType="GridListItem">
  <ImageWithCaption imageName="layout-touch-target-minimum" alt="A checkbox component using invisible padding to reach the target size of 48px" caption="The touch target minimum is 48px." type="png" mdxType="ImageWithCaption" />
    </GridListItem>
    <h3>{`Padding and scale space`}</h3>
    <p>{`Luna uses the 8px base when adding horizontal and vertical space for layout objects, as well as internal padding within components.`}</p>
    <p>{`In circumstances where elements would look cramped on larger screen sizes, spacing can adapt based on what breakpoint grouping the device falls in.`}</p>
    <p>{`These values usually go up in multiples of 8px. But the spacing of elements can increase from 16px to 24px, depending on whether the user is on a phone or computer.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="layout-16px-padding-on-mobile" alt="Example of 16px padding on mobile" caption="16px padding on mobile (below 720)" type="png" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="layout-24px-padding-on-mobile" alt="Example of 24px padding on mobile" caption="24px padding on mobile (above 719)" type="png" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <h4>{`Padding at different breakpoints`}</h4>
    <TableContainer className="ln-u-margin-bottom" mdxType="TableContainer">
  <TableHeader mdxType="TableHeader">
    <TableHeaderRow mdxType="TableHeaderRow">
      <TableHeaderCell mdxType="TableHeaderCell">Spacing name</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Breakpoints smaller than 720px</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Breakpoints 720px or more</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">default</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
      <TableCell mdxType="TableCell">24px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">sm</TableCell>
      <TableCell mdxType="TableCell">8px</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">lg</TableCell>
      <TableCell mdxType="TableCell">24px</TableCell>
      <TableCell mdxType="TableCell">32px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">xl</TableCell>
      <TableCell mdxType="TableCell">32px</TableCell>
      <TableCell mdxType="TableCell">40px</TableCell>
    </TableRow>
  </TableBody>
    </TableContainer>
    <h3>{`Aspect ratios`}</h3>
    <p>{`Aspect ratios describe the relationship between width and height. To keep things looking consistent, use consistent aspect ratios.`}</p>
    <p>{`We recommend using 16:9, 3:2, 4:3, 1:1, 3:4, and 2:3. These can apply to images, surfaces or components.`}</p>
    <GridListItem removePadding="true" mdxType="GridListItem">
  <ImageWithCaption imageName="layout-aspect-ratios" alt="Example of all possible aspect ratios" type="png" mdxType="ImageWithCaption" />
    </GridListItem>
    <hr></hr>
    <h2 {...{
      "id": "design-templates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#design-templates",
        "aria-label": "design templates permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Design templates`}</h2>
    <p>{`Design templates can be found within our `}<a parentName="p" {...{
        "href": "https://www.figma.com/@sainsburys"
      }}>{`Figma libraries`}</a>{`. They cover popular sizes for responsive web, iOS and Android.`}</p>
    <p>{`Use them as a starting point when creating a new design or presenting work on a specific device.`}</p>
    <GridListItem removePadding="true" mdxType="GridListItem">
  <ImageWithCaption imageName="layout-design-templates" alt="Example of design libraries in responsive web, iOS and Android" type="png" mdxType="ImageWithCaption" />
    </GridListItem>
    <hr></hr>
    <h2 {...{
      "id": "landmarks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#landmarks",
        "aria-label": "landmarks permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Landmarks`}</h2>
    <p>{`Screen reader users may not be able to see the visual structure of a page. Landmarks are used to provide shortcuts that allow screen reader users to jump to key sections.`}</p>
    <p>{`When a screen reader user navigates to a landmark, they'll be given a relevant description.`}</p>
    <h3>{`How to see landmarks on any page`}</h3>
    <p>{`We recommend you install the free `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/accessibility-insights-fo/pbjjkligggfmakdaogkfomddhfmpjeni?hl=en"
      }}>{`Accessibility Insights Chrome extension`}</a>{`.
You can then turn on ‘landmarks’ within ‘ad hoc tools’.`}</p>
    <h3>{`How to use landmarks`}</h3>
    <p>{`You should make sure that the key landmarks, such as the header, navigation, main content and footer are present.`}</p>
    <p>{`They can be added to a page by using a semantic element or by adding a role attribute. These include (Luna component / HTML element / role attribute):`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<Header /> / <header /> / role="banner"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<Navigation /> / <nav /> / role="navigation"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<Main /> / <main /> / role="main"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<Footer /> / <footer /> / role="contentinfo"`}</inlineCode></li>
    </ul>
    <p>{`If there's more than one navigation landmark, use an aria-label to help the user to tell between them (for example, site navigation versus in-page navigation).`}</p>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/landmark_role"
      }}>{`Get more guidance on landmarks from MDN Web Docs`}</a>{`.`}</p>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help you answer any questions around layouts or just help with your
  next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      